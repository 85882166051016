import { Icon, Text } from '@lawnstarter/ls-react-common/atoms';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Events } from '../../enums/events';
import { StepType, StepId } from '../../enums/schema';
import { useTrackPayload } from '../../hooks/useTrackPayload';
import { resetContact } from '../../store/modules/contact/slice';
import { resetProperty, selectProperty } from '../../store/modules/property/slice';
import { resetProspect } from '../../store/modules/prospect/slice';
import { trackOnAddressChange } from '../../service/segment/trackers';
import { resetCart } from '../../store/modules/cart/slice';
import { StyledWrapper, styleLabel } from './styles';

export interface AddressButtonProps {
  className?: string;
}

export const AddressButton = ({ className }: AddressButtonProps) => {
  const dispatch = useDispatch();
  const { payloadBuilder } = useTrackPayload(Events.OnAddressChange);
  const property = useSelector(selectProperty);
  const navigate = useNavigate();
  const [street] = useMemo(() => (property.address1 ? property.address1.split(',') : []), [property.address1]);

  const onClick = useCallback(() => {
    const payload = payloadBuilder();
    payload && trackOnAddressChange(payload);
    dispatch(resetProspect());
    dispatch(resetContact());
    dispatch(resetProperty());
    dispatch(resetCart());

    navigate({ pathname: `/${StepType.Cart}/${StepId.ContactInfo}` });
  }, [dispatch, navigate, payloadBuilder]);

  return (
    <StyledWrapper onClick={onClick} data-testid="address-button" className={className}>
      <Text variant="titleSmall" style={styleLabel}>
        <Icon color="home" name="home-outline" size={20} />
        &nbsp;{street}
      </Text>
    </StyledWrapper>
  );
};

import { Button, Text } from '@lawnstarter/ls-react-common/atoms';
import { lsI18NService } from '../../../../../service';
import { PromoCodeChange, PromoCodeSection, StyledLink } from '../../styles';
import { PromoCode } from '../../../../../types/graphql';

interface PromocodeProps {
  onOpen: () => void;
  promocode: PromoCode | null;
}

export const Promocode = ({ onOpen, promocode }: PromocodeProps) => {
  return (
    <PromoCodeSection>
      {promocode ? (
        <PromoCodeChange>
          <Text variant="bodySmall">
            {promocode?.description
              ? `${lsI18NService.t('promoCode.codeAdded')}: ${promocode?.description}`
              : lsI18NService.t('promoCode.codeTitle')}
          </Text>

          <StyledLink onClick={onOpen}>{lsI18NService.t('promoCode.codeChange')}</StyledLink>
        </PromoCodeChange>
      ) : (
        <Button trackID="add-promocode" onPress={onOpen} mode="text">
          {lsI18NService.t('promoCode.addAPromocode')}
        </Button>
      )}
    </PromoCodeSection>
  );
};

import { useForm, useWatch } from 'react-hook-form';

import { useExtraServices } from './useExtraServices';
import { useLawnTreatment } from './useLawnTreatment';
import { useCallback, useMemo } from 'react';
import { CartItem, addCartItem } from '../../../store/modules/cart/slice';
import { useDispatch } from 'react-redux';
import { ExtraServicesStep } from '../../../types/schema';
import { useTrackPayload } from '../../../hooks/useTrackPayload';
import { Events } from '../../../enums/events';
import { trackCrossSellSelected } from '../../../service/segment/trackers';
import _ from 'lodash';

interface Props {
  currentStep: ExtraServicesStep;
}

export const useExtraServicesController = ({ currentStep }: Props) => {
  const dispatch = useDispatch();
  const { defaultValues: defaultExtra, extraServices } = useExtraServices({ currentStep });
  const { defaultValues: defaultLtr, lawnTreatment } = useLawnTreatment();

  const { control: ltrControl, getValues: getLtr } = useForm({ defaultValues: defaultLtr, mode: 'onChange' });

  const { control: extraServicesControl, getValues: getExtraServices } = useForm({
    defaultValues: defaultExtra,
    mode: 'onChange',
  });

  const watchedLtr = useWatch({ control: ltrControl });
  const watchedExtraServices = useWatch({ control: extraServicesControl });

  const { payloadBuilder } = useTrackPayload(Events.CrossSellSelected);

  const selectedServices = useMemo(() => {
    const fields = { ...getExtraServices(), ...getLtr() };
    const values = Object.keys(fields).filter((key) => fields[key]);

    const payload = payloadBuilder({ crossSell: values.map((value: string) => _.kebabCase(value)) });
    payload && trackCrossSellSelected(payload);

    return values;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedLtr, watchedExtraServices, getExtraServices, getLtr]);

  const onQuoteServices = useCallback(
    (callback: () => void) => {
      const items = [...extraServices, ...lawnTreatment];
      const selectedItems = selectedServices.map((selected) =>
        items.find((item) => item.type === selected),
      ) as CartItem[];
      dispatch(addCartItem(selectedItems));

      callback();
    },
    [extraServices, lawnTreatment, selectedServices, dispatch],
  );

  return {
    selectedServices,
    lawnTreatment: {
      control: ltrControl,
      data: lawnTreatment,
    },
    extraServices: {
      control: extraServicesControl,
      data: extraServices,
    },
    onQuoteServices,
  };
};

import tw from 'tailwind-styled-components';

export const StyledHeader = tw.div`
  flex items-center h-20 justify-between
`;

export const StyledWrapperPhone = tw.button`
  rounded-full border border-graySecondary p-1 md:hidden
`;

export const StyledWrapper = tw.header``;

import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Icon } from '@lawnstarter/ls-react-common/atoms';
import { Brand } from '@lawnstarter/ls-react-common/enums';
import { useSelector } from 'react-redux';

import { VITE_BRAND } from '../../config/env';
import { METADATA } from '../../constants/metadata';
import { useDynamicSvgImport } from '../../hooks/useDynamicSvgImport';
import { HeaderVariants, selectApp } from '../../store/modules/app/slice';
import { StyledHeader, StyledWrapper, StyledWrapperPhone } from './styles';
import { AddressButton } from '../../components/AddressButton';
import { StyledStroke } from '../../common/styles';
import { useTrackPayload } from '../../hooks/useTrackPayload';
import { Events } from '../../enums/events';
import { trackCallClicked } from '../../service/segment/trackers';
import { CustomerSignupContext } from '../../types/track';

export default function Header() {
  const { Svg: Logo } = useDynamicSvgImport('logo');
  const { shouldShowHeader, headerRightSlot, shouldShowAddress } = useSelector(selectApp);
  const { colors, sizing } = useAppTheme();
  const { phone } = METADATA[VITE_BRAND as Brand];
  const { payloadBuilder } = useTrackPayload(Events.CallClicked);

  const onCall = () => {
    const payload = payloadBuilder({ signupContext: CustomerSignupContext.HEADER });
    payload && trackCallClicked(payload);

    window.location.assign(`tel:${phone.raw}`);
  };

  const RightSlot = () => {
    switch (headerRightSlot) {
      case HeaderVariants.DetailedBadgePhone:
        return (
          <StyledWrapperPhone onClick={onCall}>
            <Icon color={colors.primary} name="phone" size={sizing.s6} testID="detailed-badge-phone" />
          </StyledWrapperPhone>
        );
      default:
        return <></>;
    }
  };

  return (
    shouldShowHeader && (
      <StyledWrapper>
        <StyledHeader>
          <Logo />
          <RightSlot />
          {shouldShowAddress && <AddressButton className="hidden md:block" />}
        </StyledHeader>
        {shouldShowAddress && <AddressButton className="-mt-4 mb-6 md:hidden" />}
        <StyledStroke className="mb-4 md:hidden" />
      </StyledWrapper>
    )
  );
}

import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, Icon, Spinner, Text, Toast } from '@lawnstarter/ls-react-common/atoms';
import { Tooltip } from '@lawnstarter/ls-react-web-common';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { StepTitle } from '../../../components/StepTitle';
import { StyledStroke } from '../../../common/styles';
import { DatePicker } from '../../../components/DatePicker';
import { StepId } from '../../../enums/schema';
import { useSchema } from '../../../hooks/useSchema';
import { Step } from '../../../layout/Step';
import { lsI18NService } from '../../../service';
import { HeaderVariants, updateApp } from '../../../store/modules/app/slice';
import { ErrorScreen } from '../../ErrorScreen';
import { useScheduling } from './controller';
import { StyledToast, StyledWrapperLink, StyledWrapperToolTip } from './styles';
import { useWwwLead } from './useWwwLead';
import { Selector } from '../../../components/Selector';
import { usePromoCode } from '../../../hooks/usePromoCode';
import { centsToDollar } from '../../../helpers/currency';
import { CompactFaq } from './components/CompactFaq';
import { Banner } from '../../../components/Banner';
import { TOAST_MS_TO_HIDE } from '../../../constants/general';
import { Promocode } from './components/Promocode';
import { Cart } from '../../../components/Cart';
import { Events } from '../../../enums/events';
import { useTrackPayload } from '../../../hooks/useTrackPayload';
import { trackNotLookingForClicked, trackStartDateSubmitted } from '../../../service/segment/trackers';

const stepId = StepId.Scheduling;

export const Scheduling = () => {
  const theme = useAppTheme();
  const dispatch = useDispatch();
  const { vertical } = useSchema();
  const {
    property,
    selectOptions,
    isLoaded,
    hasError,
    form,
    shouldDisplayStartDate,
    currentStep,
    shouldDisplaySpinner,
  } = useScheduling();
  const { landscapingUrl } = useWwwLead();
  const { address1: street, city, zip } = property;
  const [showToast, setShowToast] = useState(false);
  const { openModal, promocode } = usePromoCode();
  const { payloadBuilder: startDatePayload } = useTrackPayload(Events.StartDateSubmitted);
  const { payloadBuilder } = useTrackPayload(Events.NotLookingForClicked);

  useEffect(() => {
    dispatch(updateApp({ headerRightSlot: HeaderVariants.DetailedBadgePhone, shouldShowAddress: true }));

    return () => {
      dispatch(updateApp({ shouldShowAddress: false }));
    };
  }, [dispatch]);

  const onDate = (date: dayjs.Dayjs | null) => {
    if (!date) {
      return;
    }

    const payload = startDatePayload({ startDate: date.format('YYYY-MM-DD') });
    payload && trackStartDateSubmitted(payload);
  };

  const onImNotLooking = (e: React.MouseEvent) => {
    e.preventDefault();
    const payload = payloadBuilder();
    payload && trackNotLookingForClicked(payload);
    window.location.href = landscapingUrl;
  };

  return !isLoaded ? (
    <></>
  ) : hasError ? (
    <ErrorScreen
      image={{ name: 'property', isBranded: false }}
      title={lsI18NService.t('calculationScreen.error.provideQuote')}
      label={`${street}, ${city}, ${zip}`}
    />
  ) : shouldDisplaySpinner ? (
    <Spinner size={theme.sizing.s13} />
  ) : (
    <Step>
      <Step.Body>
        <Step.Content>
          <StepTitle
            title={promocode?.description ?? undefined}
            stepId={stepId}
            vertical={vertical}
            titleSlot={() => <CompactFaq />}
          />
          <Banner
            className="hidden md:block"
            title={lsI18NService.t('scheduling.banner.title')}
            icon="lightbulb-outline"
          >
            {lsI18NService.t('scheduling.banner.description')}
          </Banner>
        </Step.Content>
        <Step.Form>
          {selectOptions.map((estimate) => (
            <Selector
              key={estimate.key}
              title={estimate.label}
              caption={lsI18NService.t(`${vertical}.serviceCaption`)}
              price={`$${centsToDollar(estimate.price)}`}
              onClick={() => void form.setEstimate(estimate.entity)}
              isSelected={form.estimate.id === estimate.key}
              isRecommended={currentStep.options?.recommendedCycle === estimate.entity.cycle}
            />
          ))}

          {shouldDisplayStartDate && (
            <>
              <StyledWrapperToolTip>
                <Text variant="bodyMedium">{lsI18NService.t('scheduling.dateLabel')}</Text>
                <Tooltip title={lsI18NService.t('scheduling.dateTip')} enterTouchDelay={0} arrow disableFocusListener>
                  <span className="ml-1 cursor-pointer">
                    <Icon name="information-outline" color={theme.colors.primary} size={theme.spacing.s5} />
                  </span>
                </Tooltip>
              </StyledWrapperToolTip>
              <DatePicker
                format="ddd, MMM D, YYYY"
                onChange={(date: null) => {
                  form.setStartDate(date as unknown as dayjs.Dayjs);
                }}
                onAccept={onDate}
              />
            </>
          )}

          <Promocode promocode={promocode} onOpen={() => openModal({ setShowToast })} />

          <StyledStroke className="mt-8 md:hidden" />

          <Banner className="md:hidden" title={lsI18NService.t('scheduling.banner.title')} icon="lightbulb-outline">
            {lsI18NService.t('scheduling.banner.description')}
          </Banner>
          <StyledWrapperLink onClick={onImNotLooking}>
            {lsI18NService.t('scheduling.imNotLooking', { vertical })}
          </StyledWrapperLink>

          {showToast && (
            <StyledToast>
              <Toast
                icon="check-circle-outline"
                variant="success"
                description={lsI18NService.t('promoCode.codeApplied')}
                msToHide={TOAST_MS_TO_HIDE}
              />
            </StyledToast>
          )}

          <Step.Footer>
            <Step.Action>
              <Cart promocode={promocode} price={form.estimate.amount ?? 0} />

              <Button
                onPress={form.handleSubmit}
                mode="contained"
                trackID={`${stepId}-continue`}
                loading={form.mutation.isLoading || form.mutation.isSuccess}
                disabled={!form.isValid || form.mutation.isLoading || form.mutation.isSuccess}
              >
                {lsI18NService.t('scheduling.continue')}
              </Button>
            </Step.Action>
          </Step.Footer>
        </Step.Form>
      </Step.Body>
    </Step>
  );
};

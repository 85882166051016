import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { updateContact } from '../../../store/modules/contact/slice';
import { store } from '../../../store';
import { selectContact } from '../../../store/modules/contact/slice';
import { EmailAlreadyTakenError } from '../../../constants/GraphQLErrors';
import { useSubmitProspectAsOrderMutation } from '../../../store/modules/prospect/api';
import _ from 'lodash';
import { lsI18NService } from '../../../service';

const defaultValues = {
  firstName: '',
  lastName: '',
  email: '',
  termsCheckbox: '',
};

interface AccountAndPaymentProps {
  stripeFormRef: React.RefObject<HTMLFormElement>;
  checkboxRef: React.RefObject<HTMLFormElement>;
}

export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const useAccountAndPayment = ({ stripeFormRef, checkboxRef }: AccountAndPaymentProps) => {
  const { hashId: prospectId } = useParams();
  const [submitProspectAsOrder, { data, isSuccess, isLoading, isUninitialized, error }] =
    useSubmitProspectAsOrderMutation();
  const { name, phone } = useSelector(selectContact);
  const [isStripeFormValid, setIsStripeFormValid] = useState(false);
  const [shouldHighlightError, setShouldHighlightError] = useState(false);
  const [paymentToken, setPaymentToken] = useState<string | null>(null);
  const fullName = name?.split(' ');

  const { control, formState, getValues, setError, watch } = useForm({
    mode: 'onChange',
    defaultValues: {
      ...defaultValues,
      firstName: fullName?.[0] || defaultValues.firstName,
      lastName: fullName?.[1] || defaultValues.lastName,
    },
  });
  const [isEmailAlreadyTaken, setIsEmailAlreadyTaken] = useState(false);

  // Handle validation errors
  useEffect(() => {
    const { message, error: emailError } = EmailAlreadyTakenError;

    if (error?.message?.includes(emailError)) {
      setIsEmailAlreadyTaken(true);
      setError('email', { type: 'error', message });
    }
  }, [error, setError]);

  useEffect(() => {
    watch((value, { name }) => {
      if (name !== 'termsCheckbox' || !value.termsCheckbox) {
        return;
      }

      setShouldHighlightError(false);
    });
  }, [watch, setShouldHighlightError]);

  const handleSubmit = () => {
    const { termsCheckbox, email } = getValues();
    if (!termsCheckbox) {
      setShouldHighlightError(true);
      checkboxRef.current?.scrollIntoView({ behavior: 'smooth' });
      return;
    }

    if (!EMAIL_REGEX.test(email)) {
      setError('email', { type: 'error', message: lsI18NService.t('accountAndPayment.emailInput.patternMessage') });
      return;
    }

    setShouldHighlightError(false);
    void stripeFormRef.current?.requestSubmit();
  };

  useEffect(() => {
    if (!paymentToken || !isUninitialized) return;

    const { firstName, lastName, email } = getValues();
    const contact = { name: `${firstName} ${lastName}`, phone, email };

    store.dispatch(updateContact({ email: getValues().email }));
    submitProspectAsOrder({ prospectId, contact, paymentToken });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentToken]);

  // Form validation of Contact + Stripe fields
  const isValid = useMemo(() => formState.isValid && isStripeFormValid, [formState.isValid, isStripeFormValid]);

  return {
    handleSubmit,
    setPaymentToken,
    setIsStripeFormValid,
    isEmailAlreadyTaken,
    shouldHighlightError,
    form: {
      isValid,
      control,
    },
    mutation: {
      data,
      isSuccess,
      isLoading,
    },
  };
};

import { useEffect, useState } from 'react';
import { Text } from '@lawnstarter/ls-react-common/atoms';

import { lsI18NService } from '../../../../../service';

import { useStripeForm } from './controller';
import {
  StyledCardCvcElement,
  StyledCardDetail,
  StyledCardDetailsWrapper,
  StyledCardExpiryElement,
  StyledCardNumberElement,
  StyledCardWrapper,
  StyledForm,
  Styledlabel,
  elementStyles,
  eyebrowStyle,
} from './styles';
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';

interface StripeFormProps {
  stripeFormRef: React.RefObject<HTMLFormElement>;
  setPaymentToken: (token: string) => void;
  setIsStripeFormValid: (valid: boolean) => void;
}

export const StripeForm = ({ stripeFormRef, setPaymentToken, setIsStripeFormValid }: StripeFormProps) => {
  const { handleSubmit } = useStripeForm({ setPaymentToken });
  const [isCardNumberOnFocus, setIsCardNumberOnFocus] = useState(false);
  const [isCardCvcOnFocus, setIsCardCvcOnFocus] = useState(false);
  const [isCardExpiryOnFocus, setIsCardExpiryOnFocus] = useState(false);

  const [isCardNumberValidated, setIsCardNumberValidated] = useState(false);
  const [isCardCvcValidated, setIsCardCvcValidated] = useState(false);
  const [isCardExpiryValidated, setIsCardExpiryValidated] = useState(false);

  useEffect(() => {
    setIsStripeFormValid(isCardNumberValidated && isCardCvcValidated && isCardExpiryValidated);
  }, [isCardNumberValidated, isCardCvcValidated, isCardExpiryValidated, setIsStripeFormValid]);

  return (
    <StyledForm onSubmit={handleSubmit} ref={stripeFormRef}>
      <StyledCardWrapper data-testid="bar">
        <Styledlabel htmlFor="card-number">{lsI18NService.t('accountAndPayment.cardNumberInput.label')}*</Styledlabel>
        <StyledCardNumberElement
          options={{ showIcon: true, style: elementStyles }}
          onFocus={() => setIsCardNumberOnFocus(true)}
          onBlur={() => setIsCardNumberOnFocus(false)}
          onChange={(e: StripeCardNumberElementChangeEvent) => setIsCardNumberValidated(e.complete)}
          className={isCardNumberOnFocus ? 'shadow-input border-success' : ''}
          id="card-number"
        />
      </StyledCardWrapper>
      <StyledCardDetailsWrapper>
        <StyledCardDetail>
          <Styledlabel htmlFor="card-expiry">{lsI18NService.t('accountAndPayment.cardExpiryInput.label')}*</Styledlabel>
          <StyledCardExpiryElement
            options={{ placeholder: '02/25', style: elementStyles }}
            onFocus={() => setIsCardExpiryOnFocus(true)}
            onBlur={() => setIsCardExpiryOnFocus(false)}
            onChange={(e: StripeCardExpiryElementChangeEvent) => setIsCardExpiryValidated(e.complete)}
            className={isCardExpiryOnFocus ? 'shadow-input border-success' : ''}
            id="card-expiry"
          />
        </StyledCardDetail>
        <StyledCardDetail>
          <Styledlabel htmlFor="card-cvc">{lsI18NService.t('accountAndPayment.cardCvcInput.label')}*</Styledlabel>
          <StyledCardCvcElement
            options={{ placeholder: '323', style: elementStyles }}
            onFocus={() => setIsCardCvcOnFocus(true)}
            onBlur={() => setIsCardCvcOnFocus(false)}
            onChange={(e: StripeCardCvcElementChangeEvent) => setIsCardCvcValidated(e.complete)}
            className={isCardCvcOnFocus ? 'shadow-input border-success' : ''}
            id="cvc"
          />
        </StyledCardDetail>
      </StyledCardDetailsWrapper>
      <Text variant="bodySmall" style={eyebrowStyle}>
        {lsI18NService.t('accountAndPayment.eyebrow')}
      </Text>
    </StyledForm>
  );
};

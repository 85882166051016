import { Text } from '@lawnstarter/ls-react-common/atoms';
import { CartProps } from '../..';
import { styleDescription, StyledContainer, styleTitle } from '../../styles';
import { lsI18NService } from '../../../../service';
import { centsToDollar } from '../../../../helpers/currency';
import { StyledStroke } from '../../../../common/styles';
import { TRUST_AND_SAFETY_FEE } from '../../../../constants/general';
import { PriceDetail } from '../PriceDetail';
import { useParams } from 'react-router-dom';
import { useStepNavigation } from '../../../../hooks/useStepNavigation';
import { StepId } from '../../../../enums/schema';

export const PromoDetails = ({ promocode, price }: CartProps) => {
  const originalPrice = centsToDollar(price, 2);
  const trustAndSafetyFee = centsToDollar(TRUST_AND_SAFETY_FEE, 2);

  const { stepId } = useParams();
  const { vertical } = useStepNavigation({ stepId: stepId as StepId });

  return (
    <>
      <PriceDetail label={lsI18NService.t('cart.regularPrice')} price={`$${originalPrice}`} />
      <PriceDetail
        label={lsI18NService.t('cart.discount')}
        price={`-$${centsToDollar(price - promocode!.amount, 2)}`}
      />

      <StyledStroke className="mb-2 md:block " />

      <StyledContainer>
        <Text style={styleTitle} variant="titleMedium">
          {lsI18NService.t(`cart.${vertical}.detailsTitle`)}
        </Text>
        <Text style={styleTitle} variant="titleMedium">
          ${centsToDollar(promocode!.amount)}
        </Text>
      </StyledContainer>

      <Text style={styleDescription} variant="bodySmall">
        {lsI18NService.t('cart.followingServicesAre', { amount: originalPrice })} + ${trustAndSafetyFee}&nbsp;
        {lsI18NService.t('serviceTrust')}
      </Text>
    </>
  );
};

export enum Events {
  HelpViewed = 'Customer Signup Help Viewed',
  HelpArticleExpanded = 'Customer Signup Help Article Expanded',
  OnAddressChange = 'Customer Signup Address Change Clicked',
  PageViewed = 'Customer Signup Page Viewed',
  ContactSubmitted = 'Customer Signup Contact Submitted',
  CallClicked = 'Customer Signup Call Clicked',
  StartDateSubmitted = 'Customer Signup Start Date Submitted',
  NotLookingForClicked = 'Customer Signup Not Looking For Clicked',
  PromocodeAdded = 'Customer Signup Promocode Added',
  ScheduleSubmitted = 'Customer Signup Schedule Submitted',
  CrossSellSelected = 'Customer Signup Cross Sell Selected',
}

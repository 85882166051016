import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import tw from 'tailwind-styled-components';

export const StyledForm = tw.form`
  flex flex-col flex-1
`;

export const Styledlabel = tw.label`
  text-sm
`;

export const StyledCardWrapper = tw.div`
  md:pt-6 pt-2
`;

export const StyledCardDetail = tw.div`
  mt-5 flex-1
`;

export const StyledCardDetailsWrapper = tw.div`
  flex gap-x-4 flex-wrap
`;

export const StyledCardNumberElement = tw(CardNumberElement)`
  rounded-[4px] border-2 p-4 border-graySecondary focus:shadow-input
`;

export const StyledCardCvcElement = tw(CardCvcElement)`
  rounded-[4px] border-2 p-4 border-graySecondary min-h-14 min-w-24
`;

export const StyledCardExpiryElement = tw(CardExpiryElement)`
  rounded-[4px] border-2 p-4 border-graySecondary min-h-14 min-w-24
`;

export const eyebrowStyle = {
  textAlign: 'center',
  marginBottom: 'var(--s4)',
  marginTop: 'var(--s8)',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
};

export const elementStyles = {
  base: {
    color: '#000',
    fontFamily: 'Inter',
    fontSize: '16px',

    ':focus': {
      color: '#000',
    },

    '::placeholder': {
      color: '#9b9b9b',
    },

    ':focus::placeholder': {
      color: '#9b9b9b',
    },
  },
  invalid: {
    color: 'red',
  },
};

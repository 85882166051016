import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import { selectProperty } from '../../../store/modules/property/slice';
import { ProspectServiceEstimate } from '../../../types/graphql';
import { StepId, StepType } from '../../../enums/schema';
import { useStepNavigation } from '../../../hooks/useStepNavigation';
import { useAcceptEstimateMutation, useLazyGetProspectQuery } from '../../../store/modules/prospect/api';
import { selectEstimatesByVertical, selectProspect } from '../../../store/modules/prospect/slice';
import { LOADING_DELAY_IN_MS } from '../../../constants/general';
import { lsI18NService } from '../../../service';
import { SchedulingStep } from '../../../types/schema';
import { Events } from '../../../enums/events';
import { useTrackPayload } from '../../../hooks/useTrackPayload';
import { trackScheduleSubmitted } from '../../../service/segment/trackers';
import { usePromoCode } from '../../../hooks/usePromoCode';
import { Status } from '../../../enums/prospect';

const stepId = StepId.Scheduling;

export const useScheduling = () => {
  const navigate = useNavigate();
  const { goToNextStep, vertical, hashId, currentStep } = useStepNavigation({ stepId });
  const shouldDisplayStartDate = Boolean(_.get(currentStep, 'options.shouldDisplayStartDate', true));

  const property = useSelector(selectProperty);
  const estimates = useSelector((state) => selectEstimatesByVertical(state, vertical));
  const prospect = useSelector(selectProspect);
  const [getProspect, getProspectMutation] = useLazyGetProspectQuery();

  const [estimate, setEstimate] = useState<Partial<ProspectServiceEstimate>>({});
  const [startDate, setStartDate] = useState(dayjs(new Date()).add(2, 'days'));

  const [acceptEstimate, acceptEstimateMutation] = useAcceptEstimateMutation();

  const { payloadBuilder } = useTrackPayload<Events.ScheduleSubmitted>(Events.ScheduleSubmitted);
  const { promocode } = usePromoCode();

  const selectOptions = useMemo(
    () =>
      estimates.map((estimate) => ({
        key: estimate.id,
        entity: estimate,
        label: lsI18NService.t(`cycle.${estimate.cycle}`),
        price: estimate.amount,
      })),
    [estimates],
  );

  const shouldDisplaySpinner = useMemo(() => prospect.status === Status.Completed, [prospect]);

  const hasEstimates = useMemo(() => estimates.length > 0, [estimates.length]);

  const isLoaded = useMemo(
    () => prospect.id === hashId || (!getProspectMutation.isUninitialized && !getProspectMutation.isLoading),
    [getProspectMutation.isLoading, getProspectMutation.isUninitialized, hashId, prospect.id],
  );

  const hasError = useMemo(
    () => isLoaded && (getProspectMutation.isError || !hasEstimates),
    [getProspectMutation.isError, hasEstimates, isLoaded],
  );

  useEffect(() => {
    if (!isLoaded) {
      getProspect({ id: hashId });
    }
  }, [getProspect, hashId, isLoaded]);

  useEffect(() => {
    if (!estimate.amount && hasEstimates) {
      setEstimate(estimates[0]);
    }
  }, [estimate.amount, estimates, hasEstimates]);

  useEffect(() => {
    hasError &&
      setTimeout(() => navigate(`/${StepType.Cart}/${StepId.ContactInfo}?intent=${vertical}`), LOADING_DELAY_IN_MS);
  }, [hasError, vertical, navigate]);

  useEffect(() => {
    if (!getProspectMutation.isSuccess) {
      return;
    }

    if (!prospect.id) {
      navigate(`/${StepType.Cart}/${StepId.ContactInfo}?intent=${vertical}`);
    }
  }, [getProspectMutation.isSuccess, navigate, vertical, prospect.id]);

  useEffect(() => {
    if (acceptEstimateMutation.isSuccess && goToNextStep) {
      const payload = payloadBuilder({
        frequency: estimate.cycle,
        startDate: startDate.format('YYYY-MM-DD HH:mm:ss'),
        promocode: String(promocode?.id),
        price: String(estimate.amount),
      });
      payload && trackScheduleSubmitted(payload);
      navigate(goToNextStep);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptEstimateMutation.isSuccess]);

  const handleSubmit = useCallback(() => {
    acceptEstimate({ estimateId: estimate.id, startDate: startDate.format('YYYY-MM-DD HH:mm:ss') });
  }, [acceptEstimate, estimate.id, startDate]);

  return {
    vertical,
    property,
    selectOptions,
    hasEstimates,
    isLoaded,
    hasError,
    goToNextStep,
    shouldDisplayStartDate,
    currentStep: currentStep as SchedulingStep,
    shouldDisplaySpinner,
    form: {
      handleSubmit,
      isValid: Boolean(estimate.amount && startDate),
      estimate,
      setEstimate,
      startDate,
      setStartDate,
      mutation: acceptEstimateMutation,
    },
  };
};

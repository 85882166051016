import { useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectContact } from '../store/modules/contact/slice';
import { selectProperty } from '../store/modules/property/slice';
import { Status as ProspectStatus } from '../enums/prospect';
import { StepId, StepType } from '../enums/schema';
import { selectProspect } from '../store/modules/prospect/slice';
import { VITE_MY_LOGIN_URL } from '../config/env';

export const verticalSelectionStep = `/${StepType.Cart}/${StepId.VerticalSelection}`;
export const contactInfoStep = `/${StepType.Cart}/${StepId.ContactInfo}`;

export const useCheckValidState = () => {
  const navigate = useNavigate();
  const contact = useSelector(selectContact);
  const property = useSelector(selectProperty);
  const prospect = useSelector(selectProspect);
  const { hashId: prospectId } = useParams();
  const { pathname } = useLocation();

  useEffect(() => {
    const nonNavigableRoutes = [
      verticalSelectionStep,
      contactInfoStep,
      `/${StepType.Cart}/${prospectId}/${StepId.ExtraServices}`,
      `/${StepType.Cart}/${prospectId}/${StepId.LawnTreatment}`,
      `/${StepType.Cart}/${prospectId}/${StepId.AccountAndPayment}`,
      `/${StepType.Cart}/${prospectId}/${StepId.Welcome}`,
      `/${StepType.Cart}`,
      `/`,
    ];

    if (!prospect.id || nonNavigableRoutes.includes(pathname)) {
      return;
    }

    if (prospect.status === ProspectStatus.Completed) {
      window.location.replace(VITE_MY_LOGIN_URL);
      return;
    }

    if (!contact.name || !property.address1) {
      navigate({ pathname: contactInfoStep });
    }
  }, [contact, property, navigate, pathname, prospectId, prospect]);
};

import tw from 'tailwind-styled-components';

export const StyledWrapperBanner = tw.div`
  hidden lg:block max-w-[550px]
`;

export const StyledWrapperToolTip = tw.form`
  mb-2 mt-8 flex
`;

export const StyledWrapperLink = tw.button`
  underline text-[var(--bluePrimary)] text-center w-full block mt-8
`;

export const PromoCodeSection = tw.div`
  w-full mt-6
`;

export const PromoCodeChange = tw.div`
  flex justify-between items-center
`;

export const StyledLink = tw.span`
  underline text-[var(--bluePrimary)] text-sm font-inter cursor-pointer
`;

export const StyledToast = tw.div`
  fixed bottom-4 w-[98%] z-[100] inset-x-0 mx-auto pointer-events-none
`;

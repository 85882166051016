import { Text } from '@lawnstarter/ls-react-common/atoms';
import { styleDescription, StyledContainer, StyledDetail, styleTitle } from '../../styles';
import { lsI18NService } from '../../../../service';
import { centsToDollar } from '../../../../helpers/currency';
import { TRUST_AND_SAFETY_FEE } from '../../../../constants/general';
import { useParams } from 'react-router-dom';
import { useStepNavigation } from '../../../../hooks/useStepNavigation';
import { StepId } from '../../../../enums/schema';

export const RegularDetails = ({ price }: { price: number }) => {
  const { stepId } = useParams();
  const { vertical } = useStepNavigation({ stepId: stepId as StepId });

  return (
    <StyledDetail>
      <StyledContainer>
        <Text style={styleTitle} variant="titleMedium">
          {lsI18NService.t(`cart.${vertical}.title`)}
        </Text>
        <Text style={styleTitle} variant="titleMedium">
          ${centsToDollar(price)}
        </Text>
      </StyledContainer>

      <Text style={styleDescription} variant="bodySmall">
        + ${centsToDollar(TRUST_AND_SAFETY_FEE, 2)} {lsI18NService.t('serviceTrust')}
      </Text>
    </StyledDetail>
  );
};
